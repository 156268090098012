import { RootState } from '@store/rootReducer'

export const selectProjects = (state: RootState) => state.projects.projects

export const selectCurrentProject = (state: RootState) => state.projects.currentProject
export const selectCurrentProjectId = (state: RootState) => state.projects.currentProject?.id
export const selectProjectSlideOrder = (state: RootState) =>
  state.projects.currentProject?.slideOrder || []
export const selectTemplate = (state: RootState) => state.projects.templates
export const selectLayouts = (state: RootState) => state.projects.layouts
export const selectProjectEditStatusByIds = (state: RootState) => state.projects.projectsEditStatus
