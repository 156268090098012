import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { notification, Button, Dropdown, Input, Menu, Space, Modal } from 'antd'

import i18n from 'i18next'

import { RootState } from '@/store/rootReducer'
import { selectCurrentUser } from '@/store/slices/auth/selectors'
import { AppDispatch } from '@/store/store'
import { selectCanvasInteractionMode } from '@store/slices/canvas/canvas/selectors'
import { downloadSlideJson, uploadSlideJson } from '@store/slices/slides/actions'
import { downloadSlideTemplate, downloadTemplate } from '@store/slices/template/actions'

import ShapesList from '@components/imagemap/main/ShapesList'

import { CommonButton } from '../../common'
import { Flex } from '../../flex'
import AppendixReportExportConfirguration from './AppendixReportExportConfiguration'
import BulkRepeatSlide from './BulkRepeatSlide'
import CopyShapeConf from './CopyShapeConf'

import './HeaderToolbar.less'

import { selectAllSlideIds } from '@/store/slices/slides/selectors'

import { downloadPGAPayload } from '@/services/slide-service'
import { Parser } from '@utils/parser'
import { isNumber } from 'lodash'

const HeaderToolbar = () => {
  const dispatch: AppDispatch = useDispatch()
  const projectId = useSelector((state: RootState) => state?.projects?.currentProject?.id)
  const currentSlideId = useSelector((state: RootState) => state?.slides?.current)
  const slideIds = useSelector(selectAllSlideIds)
  const currentUser = useSelector(selectCurrentUser)
  const interactionMode = useSelector(selectCanvasInteractionMode)
  const [showShapelistDrawer, setShowShapelistDrawer] = useState(false)
  const [showRepeatSlideMenu, setShowRepeatSlideMenu] = useState(false)
  const [appendixModalVisible, setAppendixModalVisible] = useState(false)
  const [downloadSlides, setDownloadSlides] = useState<number[]>([])
  const [showSelectSlides, setShowSelectSlides] = useState(false)

  const isCropping = interactionMode === 'crop'

  const validateSlideIdInput = (ids: (number | string)[]): boolean => {
    if (!ids.length) return false
    if (ids.some(id => !isNumber(id) || id < 0 || id >= slideIds.length)) return false
    return true
  }

  const validSlideIdInput = validateSlideIdInput(downloadSlides)

  const openNotification = error => {
    notification.warning({
      key: error,
      duration: 5,
      message: 'Corrupted Slide Detected',
      description: `One or more slides in your project are corrupted and will not be included in the exported PowerPoint file. 
        Please review the following slides for more information:\n ${error}`
    })
  }

  const openShapeListDrawer = () => {
    if (showShapelistDrawer) {
      setShowShapelistDrawer(false)
    } else {
      setShowShapelistDrawer(true)
    }
  }

  /** Exposes PGA payload of the current slide (for dev purposes) */
  const onDownloadPGAPayload = async () => {
    const json = await downloadPGAPayload(projectId, currentSlideId)
    window.open(
      URL.createObjectURL(
        new Blob([JSON.stringify(json)], {
          type: 'application/json'
        })
      )
    )
  }

  const onRepeatSlide = () => {
    setShowRepeatSlideMenu(true)
  }

  const onChangeDownloadSlide = e => {
    const parser = new Parser()
    let value = parser.parse(e.target.value).map(val => val - 1)
    setDownloadSlides(value)
  }

  useEffect(() => {
    setShowShapelistDrawer(false)
  }, [currentSlideId])

  function handleMenuClick({ key }) {
    if (key === 'download-specified') {
      setShowSelectSlides(true)
    }
    if (key === 'download-current-slide') {
      dispatch(downloadSlideTemplate([currentSlideId]))
    }
    if (key === 'download-presentation') {
      dispatch(downloadTemplate()).catch(e => {
        openNotification(
          e.corruptedSlidesObject?.map(
            (corruptedSlideError: string) =>
              slideIds.indexOf(Number(corruptedSlideError.split(':')[0])) + 1
          )
        )
      })
    }
  }

  const specifySlides = e => {
    const parser = new Parser()
    let value = parser.parse(e.target.value).map(val => val - 1)
    setDownloadSlides(value)
  }

  const downloadSpecifiedSlides = () => {
    const slideIDs = slideIds.filter((_, index) => downloadSlides.includes(index))
    dispatch(downloadSlideTemplate(slideIDs)).finally(() => setShowSelectSlides(false))
  }

  const bulkActionsMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="download-current-slide">Download current slide</Menu.Item>
      <Menu.Item key="download-presentation">Download all slides</Menu.Item>
      <Menu.Item key="download-specified">Download specific slides</Menu.Item>
    </Menu>
  )

  return (
    <Flex
      id="header-toolbar"
      className="rde-editor-header-toolbar-container canvas-header-toolbar"
      flex="1"
      alignItems="center"
    >
      <Flex>
        <Space>
          <div style={{ paddingLeft: '10px' }}>
            <Button
              type="text"
              onClick={() => {
                setShowShapelistDrawer(true)
              }}
              id="header-toolbar-shapes-list-button"
            >
              Layers
            </Button>
            {showShapelistDrawer && (
              <div className="shapes-list-component">
                <ShapesList
                  openShapeListDrawer={openShapeListDrawer}
                  showShapelistDrawer={showShapelistDrawer}
                />
              </div>
            )}
          </div>

          <div
            style={{
              borderLeft: '1px solid #c5c5c5',
              borderRight: '1px solid #c5c5c5',
              paddingLeft: '10px'
            }}
          >
            <Button type="text" onClick={() => onRepeatSlide()}>
              Repeat slide
            </Button>
          </div>

          <div
            style={{
              paddingLeft: '10px',
              paddingRight: '10px'
            }}
          >
            <CopyShapeConf />
          </div>

          <div
            id="help-after-this"
            style={{
              borderLeft: '1px solid #c5c5c5',
              paddingRight: '10px',
              paddingBottom: '5px'
            }}
          ></div>
        </Space>
      </Flex>
      <Flex.Item id="admin-toolbar" className="rde-canvas-toolbar rde-canvas-toolbar-history">
        <div>
          <Dropdown disabled={slideIds.length === 0} trigger={['click']} overlay={bulkActionsMenu}>
            <Button type="text">Download</Button>
          </Dropdown>
        </div>
        {(currentUser?.isStaff || currentUser?.isSuperuser) && (
          <>
            <CommonButton
              id="admin-toolbar-upload"
              className="rde-action-btn"
              shape="circle"
              disabled={isCropping}
              onClick={() => dispatch(uploadSlideJson())}
              icon="file-upload"
              tooltipPlacement="bottom"
              tooltipTitle={i18n.t('editor.main-panel.header-toolbar.upload-json')}
            />
            <CommonButton
              className="rde-action-btn"
              id="admin-toolbar-download"
              shape="circle"
              disabled={isCropping}
              onClick={() => dispatch(downloadSlideJson())}
              icon="file-download"
              tooltipPlacement="bottom"
              tooltipTitle={i18n.t('editor.main-panel.header-toolbar.download-json')}
            />
            <CommonButton
              className="rde-action-btn"
              id="admin-toolbar-download"
              shape="circle"
              disabled={isCropping}
              onClick={() => onDownloadPGAPayload()}
              icon="file-download"
              tooltipPlacement="bottom"
              tooltipTitle="Download PGA payload"
            />
          </>
        )}
      </Flex.Item>
      <AppendixReportExportConfirguration
        isVisible={appendixModalVisible}
        setIsVisible={setAppendixModalVisible}
      />
      {showRepeatSlideMenu && (
        <BulkRepeatSlide show={showRepeatSlideMenu} setVisibility={setShowRepeatSlideMenu} />
      )}
      <Modal
        visible={showSelectSlides}
        title="Specify slides to download"
        okButtonProps={{ disabled: !validSlideIdInput }}
        onOk={downloadSpecifiedSlides}
        onCancel={() => setShowSelectSlides(false)}
      >
        <Input
          status={validSlideIdInput ? "" : "error"}
          onChange={specifySlides}
          placeholder="Provide comma separated values or/and ranges, e.g. 1, 3-5"
        />
      </Modal>
    </Flex>
  )
}

export default HeaderToolbar
