import { SyntheticEvent, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Card } from 'antd'
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import placeholderImage from '@assets/project-placeholder.png'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import dayjs from 'dayjs'
import i18n from 'i18next'
import { editProjectName } from '@/services/project-service'
import { Flex } from '@/components/flex'
import { ProjectDto } from '@/interfaces/projects'
import { useThumbnails } from '@/hooks/useThumbnail'
import './styles.less'

interface Props {
  item: ProjectDto
  status: Boolean
  onDuplicate: (id: number) => void
  onDelete: (id: number) => void
}

const ProjectItem = ({ item, status = true, onDuplicate, onDelete }: Props) => {
  const history = useHistory()
  const [projectName, setProjectName] = useState(item?.name)
  const slideIds = item.slideOrder && item.slideOrder.length > 0 ? [item.slideOrder[0]] : []
  const { thumbnails } = useThumbnails(slideIds, item?.id)
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id.toString()
  })
  const style = {
    maxWidth: 360,
    transform: CSS.Transform.toString(transform),
    transition
  }

  const handleOnBlur = async (e?) => {
    if (projectName && item.name !== projectName) {
      try {
        await editProjectName(item.id, projectName)
      } catch (error) {
        console.error(error.message)
      }
    }
  }

  const handleOnKeyDown = async e => {
    if (e.keyCode === 13 && projectName && projectName !== item.name) {
      try {
        await editProjectName(item.id, projectName)
      } catch (error) {
        console.error(error.message)
      }
      e.target.blur()
    }
  }

  const handleOnChange = async (e: SyntheticEvent<HTMLTextAreaElement>) => {
    setProjectName(e.currentTarget.value)
  }

  useEffect(() => {
    setProjectName(item?.name)
  }, [item])

  return (
    <div ref={setNodeRef} style={style} className={`card ${status ? "" : "unavailable"}`}>
      <Card
        cover={
          <img
            src={thumbnails?.[item.slideOrder[0]] || placeholderImage}
            onClick={() => history.push(`/app/${item.id}`)}
            style={{ cursor: 'pointer', height: 176, width: 290 }}
          />
        }
        actions={[
          <Link to={`/app/${item.id}`}>
            <Button style={{ marginRight: '5%' }} title="Open">
              <EditOutlined />
            </Button>
          </Link>,
          <Button
            onClick={() => onDuplicate(item.id)}
            disabled={item.slideOrder === null || item.slideOrder.length === 0}
            title="Duplicate"
          >
            <CopyOutlined />
          </Button>,
          <Button onClick={() => onDelete(item.id)} title="Delete">
            <DeleteOutlined />
          </Button>
        ]}
      >
        <Flex justifyContent="space-between">
          <div>
            <div>
              <textarea
                className="card-input"
                wrap="soft"
                rows={2}
                name="text"
                value={projectName}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onKeyDown={handleOnKeyDown}
              />
            </div>
            <p style={{ fontWeight: 'bold', display: 'inline' }}>
              {i18n.t('editor.projects.created')}{' '}
            </p>
            <p style={{ display: 'inline' }}>{dayjs(item.createdAt).format('D-MMM-YY HH:mm')}</p>
          </div>
          <Flex justifyContent="center" alignItems="center">
            {/* <HolderOutlined {...attributes} {...listeners} className="card-holder" /> */}
          </Flex>
        </Flex>
      </Card>
    </div>
  )
}

export default ProjectItem
