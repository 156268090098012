import i18n from 'i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentUser } from '@/store/slices/auth/selectors'
import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { updateOneCanvasObject } from '@/store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { resetShape } from '@/services/shape-service'

import { DefaultPane } from './DataPane'
import DataSourceSelector from '@/components/imagemap/right-panel/data-source/DataSourceSelector'
import '../styles.less'

const FancyDataSelector = () => {
  const dispatch = useDispatch()
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const projectId = useSelector(selectCurrentProjectId)
  const clearDataSelections = async () => {
    try {
      await resetShape({ projectId, shapeId: selectedObject.pk })
      dispatch(
        updateOneCanvasObject({
          id: selectedObject.id,
          changes: { data: null, visualSettings: null }
        })
      )
    } catch (e) {
      console.error(`An error occurred while attempting to clear data selections: ${e.message}`)
    }
  }
  return <DefaultPane onRemoveDataClick={clearDataSelections} />
}

const LameDataSelector = () => {
  const singleColumnTypes = ['PIE', 'PIE_EXPLODED', 'DOUGHNUT', 'DOUGHNUT_EXPLODED']
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const dataSourceId = selectedObject.data?.definition?.dataSourceId
  const dataTransformationId = selectedObject.data?.definition?.transformationId
  const dataSectionId = selectedObject.data?.definition?.dataSectionId
  const dataTableId = selectedObject.data?.definition?.tableId
  const isPieChart = singleColumnTypes.indexOf(selectedObject?.chart?.type) > -1
  const selectColumnsMode = isPieChart ? '' : 'multiple'
  return (
    <div className="data-selector">
      <div className="ant-tabs-tabpane-header">
        {i18n.t('editor.right-panel.data-source.title')}
      </div>
      <div className="form">
        <DataSourceSelector
          dataSourceId={dataSourceId}
          dataSectionId={dataSectionId}
          dataTableId={dataTableId}
          transformationId={dataTransformationId}
          selectColumnsMode={selectColumnsMode}
          selectRowsMode="multiple"
        />
      </div>
    </div>
  )
}

const DefaultDataSelector = () => {
  const experimentalFeaturesEnabled =
    useSelector(selectCurrentUser)?.features?.dataView || false
  return experimentalFeaturesEnabled ? <FancyDataSelector /> : <LameDataSelector />
}

export default DefaultDataSelector
