import { createAsyncThunk } from '@reduxjs/toolkit'

import { RootState } from '@store/rootReducer'
import {
  getProjectLayouts,
  getProjectTemplates,
  setProjectSlidesOrder
} from '@/store/slices/projects/actions'
import { setDataSources } from '@store/slices/data/action'
import { setCurrentProject, setProjects, setProjectEditStatusByIds } from '@store/slices/projects/actions'
import { setTemplate } from '@store/slices/template/actions'

import { getCeleryTaskStatus } from '@services/celery-service'
import * as api from '@services/project-service'

import { CeleryStatus } from '@/interfaces/celery'
import { DeleteProjectResponse, ProjectDto } from '@/interfaces/projects'

export const getProjects = createAsyncThunk<ProjectDto[], never, { rejectValue: string }>(
  'projects/getProjects',
  async (_arg, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.getProjects()
      dispatch(setProjects(response))
      return response
    } catch (e) {
      return rejectWithValue(e.response?.data?.error?.message || null)
    }
  }
)

export const getCurrentProject = createAsyncThunk<
  ProjectDto,
  { projectId: number },
  { rejectValue: string }
>('projects/getCurrentProject', async ({ projectId }, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.getProjectByID({ projectId })
    const template = Array.isArray(response?.templates) ? response.templates : null // FIXME later when multiple template is supported

    dispatch(setCurrentProject(response))
    dispatch(setDataSources(response.dataSources || []))
    dispatch(setTemplate(template))
    return response
  } catch (e) {
    return rejectWithValue(e.response?.data?.error?.message || null)
  }
})

export const removeProjectById = createAsyncThunk<
  CeleryStatus,
  { projectId: number },
  { rejectValue: CeleryStatus }
>('projects/removeProjectById', async ({ projectId }, { getState, dispatch, rejectWithValue }) => {
  try {
    const state = (getState() as RootState).projects
    const allProjects = state.projects
    const projectResponse: DeleteProjectResponse = await api.removeProjectByID(projectId)

    return new Promise(resolve => {
      const interval = setInterval(async () => {
        try {
          const response = await getCeleryTaskStatus({ taskId: projectResponse.taskId })

          if (response.status === CeleryStatus.SUCCESS) {
            clearInterval(interval)
            const updatedProjects = allProjects.filter(project => project.id !== projectId)
            dispatch(setProjects(updatedProjects))
            resolve(CeleryStatus.SUCCESS)
          } else if (response.status === CeleryStatus.FAILURE) {
            clearInterval(interval)
            resolve(rejectWithValue(CeleryStatus.FAILURE))
          }
        } catch (e) {
          clearInterval(interval)
          resolve(rejectWithValue(CeleryStatus.FAILURE))
        }
      }, 1000)
    })
  } catch (err) {
    return rejectWithValue(CeleryStatus.FAILURE)
  }
})

export const createProject = createAsyncThunk<
  ProjectDto,
  { projectType: string },
  { rejectValue: string }
>('projects/createProject', async ({ projectType }, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = (getState() as RootState).projects
    const allProjects = state.projects
    const response: ProjectDto = await api.createProject(projectType)
    dispatch(setProjects([...allProjects, response]))
    return response
  } catch (err) {
    return rejectWithValue(err.response?.data?.error?.message || null)
  }
})

export const duplicateProject = createAsyncThunk<
  ProjectDto,
  { projectId: number },
  { rejectValue: string }
>('projects/duplicateProject', async ({ projectId }, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = (getState() as RootState).projects
    const allProjects = state.projects
    const response = await api.duplicateProjectByID(projectId)
    const projects = await api.getProjects()
    dispatch(setProjects(projects))
    return response
  } catch (e) {
    return rejectWithValue(e.response?.data?.error?.message || null)
  }
})

export const updateCurrentProject = createAsyncThunk<
  ProjectDto,
  { projectId: number; payload: Partial<ProjectDto> },
  { rejectValue: string }
>('projects/reorderSlides', async ({ projectId, payload }, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.updateProject(projectId, payload)
    dispatch(setProjectSlidesOrder(response.slideOrder))
    return response
  } catch (error) {
    return rejectWithValue(error.response?.data?.error?.message || null)
  }
})

export const getProjectTemplatesThunk = createAsyncThunk(
  'project/getProjectTemplates',
  async (val: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.getProjectTemplateList(val.token)
      dispatch(getProjectTemplates(response))
      return response
    } catch (error) {
      return rejectWithValue(error.response?.data?.error?.message || null)
    }
  }
)

export const getProjectLayout = createAsyncThunk(
  'projects/getProjectLayout',
  async (val: { projectId: string | number; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.getProjectLayoutApi(val)
      dispatch(getProjectLayouts(response))
      return response
    } catch (error) {
      return rejectWithValue(error.response?.data?.error?.message || null)
    }
  }
)

export const completeAutoReport = createAsyncThunk(
  'projects/completeProject',
  async (val: { projectId: number; token: string }, { rejectWithValue }) => {
    try {
      const response = await api.completeAutoReportApi(val)
      return response
    } catch (err) {
      return rejectWithValue(err.response?.data?.error?.message || null)
    }
  }
)

export const setProjectEditStatusById = createAsyncThunk<
  ProjectDto,
  { id: number },
  { rejectValue: string }
>('projects/setProjectEditStatusById', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await api.setProjectEditStatusById({ id: id })
    return response
  } catch (error) {
    return rejectWithValue(error.response?.data?.error?.message || null)
  }
})

export const unsetProjectEditStatusById = createAsyncThunk<
  ProjectDto,
  { id: number },
  { rejectValue: string }
>('projects/unsetProjectEditStatusById', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await api.unsetProjectEditStatusById({ id: id })
    return response
  } catch (error) {
    return rejectWithValue(error.response?.data?.error?.message || null)
  }
})

export const getProjectEditStatusByIds = createAsyncThunk<
  any,
  { ids: number[] },
  { rejectValue: string }
>('projects/getProjectEditStatusByIds', async ({ ids }, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.getProjectEditStatusByIds({ ids: ids })
    dispatch(setProjectEditStatusByIds(response))
    return response
  } catch (error) {
    return rejectWithValue(error.response?.data?.error?.message || null)
  }
})

