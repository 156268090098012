import axios, { CancelToken } from 'axios'

import { DataSourceDto } from '@/interfaces/data'
import { DeleteProjectResponse, ProjectDto, ProjectFileTypes } from '@/interfaces/projects'

const API_URL = '/api/projects'

export async function createProject(projectType: string): Promise<ProjectDto> {
  const response = await axios.post<ProjectDto>(`${API_URL}/`, {
    project_type: projectType
  })
  return response.data
}

export async function getProjects(): Promise<ProjectDto[]> {
  const response = await axios.get<ProjectDto[]>(API_URL)
  return response.data
}

export async function getProjectByID(
  { projectId }: { projectId: number },
  cancelToken?: CancelToken
): Promise<ProjectDto> {
  const response = await axios.get<ProjectDto>(`${API_URL}/${projectId}`, { cancelToken })
  return response.data
}

export async function removeProjectByID(projectId: number): Promise<DeleteProjectResponse> {
  const response = await axios.delete<DeleteProjectResponse>(`${API_URL}/${projectId}`)
  return response.data
}

export async function updateProject(
  projectId: number,
  payload: Partial<ProjectDto>
): Promise<ProjectDto> {
  const url = `${API_URL}/${projectId}/`
  const response = await axios.put(url, payload)
  return response.data
}

export async function setProjectEditStatusById(
  { id }: { id: number },
): Promise<ProjectDto> {
  const url = `/api/projects-edit/set_project_edit_status/`
  const response = await axios.post(url, {id: id})
  return response.data
}

export async function unsetProjectEditStatusById(
  { id }: { id: number },
): Promise<ProjectDto> {
  const url = `/api/projects-edit/unset_project_edit_status/`
  const response = await axios.post(url, {id: id})
  return response.data
}

export async function getProjectEditStatusByIds(
  { ids }: { ids: number[] },
): Promise<ProjectDto[]> {
  const url = `/api/projects-edit/get_project_edit_status/`
  const response = await axios.post(url, {ids: ids})
  return response.data
}

export async function updateProjectUpdateTime(projectId: number): Promise<ProjectDto> {
  const url = `${API_URL}/${projectId}/`
  const payload = { projects_pk: projectId }
  const response = await axios.put(url, payload)
  return response.data
}

export async function editProjectName(projectId: number, name: string): Promise<ProjectDto> {
  const response = await axios.put(`${API_URL}/${projectId}/`, { name })
  return response.data
}

export async function duplicateProjectByID(projectId: number): Promise<ProjectDto> {
  const response = await axios.post<ProjectDto>(`${API_URL}/${projectId}/duplicate/`)
  return response.data
}

export interface UploadDataFile {
  projectId: number
  type: ProjectFileTypes
  fileName: string
  dataId?: number
  parserId?: number
}

export interface UploadDataResponse {
  url: string
  data?: DataSourceDto
}

export async function uploadProjectFile(
  { fileName, projectId, type, parserId, dataId }: UploadDataFile,
  cancelToken?: CancelToken
): Promise<UploadDataResponse> {
  const response = await axios.post(
    `${API_URL}/${projectId}/upload/`,
    {
      filename: fileName,
      type: type,
      parserId: parserId,
      dataId: dataId
    },
    {
      cancelToken
    }
  )
  return response.data
}

export async function getProjectTemplateList(token) {
  const response = await axios.get(`api/template-projects`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export async function getProjectLayoutApi(data: { projectId: string | number; token: string }) {
  const response = await axios.get(`api/template-projects/${data.projectId}`, {
    headers: {
      Authorization: `Bearer ${data.token}`
    }
  })
  return response.data
}

export async function createProjectFromTemplate(
  projectId: number,
  token: string
): Promise<{ id: number }> {
  const response = await axios.post(
    `api/template-projects/`,
    { id: projectId },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export async function updateAutoReport(projectId: number, data: any[]) {
  const response = await axios.put(`api/template-projects/${projectId}/`, data)
  return response.data
}

export async function completeAutoReportApi(val: { projectId: number; token: string }) {
  await axios.put(`api/template-projects/${val.projectId}/complete/`, {
    headers: {
      Authorization: `Bearer ${val.token}`
    }
  })
}
