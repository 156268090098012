import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert, Layout } from 'antd'

import { unwrapResult } from '@reduxjs/toolkit'

import { getCurrentProject, setProjectEditStatusById, unsetProjectEditStatusById } from '@/store/slices/projects/thunks'
import { clearAllSlides, fetchAllSlides } from '@/store/slices/slides/actions'
import { AppDispatch } from '@/store/store'
import {
  selectCanvasLoading,
  selectCanvasLoadingMessage
} from '@store/slices/canvas/canvas/selectors'

import EditorLeftPanel from '@components/imagemap/EditorLeftPanel'
import EditorMainPanel from '@components/imagemap/EditorMainPanel'
import EditorRightPanel from '@components/imagemap/EditorRightPanel'

import Container from '../../components/common/Container'
import { Navbar } from '../../components/common/Navbar'

import '../../libs/fontawesome-5.2.0/css/all.css'
import '../../styles/index.less'

import { setCanvasEmptyWorkArea } from '@/store/slices/canvas/canvas/actions'
import { zoomToFit } from '@/store/slices/canvas/zoom/actions'
import { removeAllCanvasObjects } from '@/store/slices/shapes/actions'
import { selectCurrentUser } from '@/store/slices/auth/selectors'

const { Content } = Layout

const Editor = ({ match }) => {
  const currentUser = useSelector(selectCurrentUser)
  const dispatch: AppDispatch = useDispatch()
  const loading = useSelector(selectCanvasLoading)
  const loadingMsg = useSelector(selectCanvasLoadingMessage)
  const [addBlock, setAddBlock] = useState(false)
  const { id: projectId } = match.params


  useEffect(() => {
    const load = async () => {
      await dispatch(getCurrentProject({ projectId: projectId }))
        .then(unwrapResult)
        .then(project => {
          if (project.slideOrder.length > 0) {
            dispatch(fetchAllSlides())
          } else {
            dispatch(clearAllSlides())
            dispatch(removeAllCanvasObjects())
            dispatch(setCanvasEmptyWorkArea())
          }
        })
        .catch(e => console.error(e))
    }
    load()
  }, [projectId])

  useEffect(() => {
    setTimeout(() => {
      if (
        typeof window['pendo'] !== 'undefined' &&
        window['pendo'].hasOwnProperty('validateEnvironment')
      ) {
      } else {
        setAddBlock(true)
      }
    }, 2000)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser?.enableProjectSharing) {
        await dispatch(setProjectEditStatusById({ id: projectId }))
      }
    };

    // Call the API immediately
    fetchData();

    // Set up the interval to call the API every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Clean up function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
        dispatch(unsetProjectEditStatusById({ id: projectId }))
    }
  }, [currentUser?.enableProjectSharing]);

  const content = (
    <div className="rde-editor">
      <EditorLeftPanel />
      <EditorMainPanel />
      <EditorRightPanel />
    </div>
  )

  return (
    <Layout>
      {addBlock && (
        <Alert
          style={{ textAlign: 'center' }}
          message={
            <span>
              This website contains elements that may be rendered unusable if using any adblocking
              plugins. Please disable these, or whitelist this website, to ensure you get the best
              possible user experience. You may need to refresh the page after.
            </span>
          }
          type="error"
        />
      )}
      <Navbar onPage="editor" />
      <Content>
        <Container content={content} loading={loading} loadingMsg={loadingMsg} className="" />
      </Content>
    </Layout>
  )
}

export default Editor
