import { useEffect, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'

import { Input, Button, Form, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Flex } from '@components/flex'
import CommonDetail from '@components/common/CommonDetail'

const { TextArea } = Input;

export const ProjectDetails = ({ onComplete, onCancel, Layout }) => {
  const cancelToken: CancelTokenSource = axios.CancelToken.source();
  const [data, setData] = useState<any>({});
  const [form] = useForm()

  const handleChangeData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        // Handle form submission with validated values
        onComplete({
          ...values
        });
      })
      .catch((errorInfo) => {
        // Handle validation failure
        console.error('Validation failed:', errorInfo);
      });
  };

  const content = (
    <CommonDetail
      className="project-detail"
      style={{
        minWidth: 'auto'
      }}
    >
      <div className="slide__text">
        <h1 className="slide__title">Project Details</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
      </div>
      <Flex justifyContent="center">
        <Form layout="vertical" form={form}>
          <Space size="small" direction="vertical">
            <Form.Item
              label="Let's start by naming this presentation:"
              name="name"
              rules={[
                { required: true, message: 'Please enter a value' },
                { min: 1, message: 'Minimum 1 character is required' },
                { max: 100, message: 'Maximum 100 characters are allowed' },
              ]}
            >
              <Input
                className='custom-input-outline'
                name="name"
                onChange={handleChangeData}
              />
            </Form.Item>
            <Form.Item
              label="Tell us a bit about your business or yoru clients"
              name="businessSummary"
            // rules={[
            //   { required: true, message: 'Please enter a value' },
            //   { min: 50, message: 'Minimum 50 character is required' },
            //   { max: 1000, message: 'Maximum 1000 characters are allowed' },
            // ]}
            >
              <TextArea
                className='custom-input-outline project-detail-text-width'
                placeholder='-What is the nature of your business?
-Which industry do you operate in?
-What specific products or services are you focused on?
-Who are your customers and what is your target audience?'
                name="businessSummary"
                onChange={handleChangeData}
                rows={4}
              />
            </Form.Item>
            <Form.Item
              label="What is the business problem?"
              name="businessProblem"
            // rules={[
            //   { required: true, message: 'Please enter a value' },
            //   { min: 50, message: 'Minimum 50 character is required' },
            //   { max: 1000, message: 'Maximum 1000 characters are allowed' },
            // ]}
            >
              <TextArea
                className='custom-input-outline project-detail-text-width'
                placeholder='-Define the business problem and its causes.
-Explain the importance of solving it, including risks and benefits.
-Identify existing hypotheses or assumptions.
-Describe past efforts to solve the problem.
-Highlight knowledge gaps to address.'
                name="businessProblem"
                onChange={handleChangeData}
                rows={4}
              />
            </Form.Item>
            <Form.Item
              label="What are the research objectives of this project?"
              name="researchObject"
            // rules={[
            //   { required: true, message: 'Please enter a value' },
            //   { min: 50, message: 'Minimum 50 character is required' },
            //   { max: 1000, message: 'Maximum 1000 characters are allowed' },
            // ]}
            >
              <TextArea
                className='custom-input-outline project-detail-text-width'
                placeholder='-What specific areas do you want to focus on?
-What do you expect the research to accomplish?
-Write 4-5 objectives'
                name="researchObject"
                onChange={handleChangeData}
                rows={4}
              />
            </Form.Item>
          </Space>
        </Form>
      </Flex>
    </CommonDetail>
  )
  return (
    <Layout
      previous={
        <Button onClick={() => onCancel()}>
          Previous
        </Button>
      }
      next={
        <Button type="primary" onClick={handleSubmit}>
          Next
        </Button>
      }
    >
      {content}
    </Layout>
  )
}
