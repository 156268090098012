import { createAction } from '@reduxjs/toolkit'

import { ProjectDto } from '@/interfaces/projects'
import { Template } from '@/interfaces/template'
import { Layouts } from '@/interfaces/layouts'

export const setProjects = createAction<ProjectDto[]>('projects/setProjects')
export const setCurrentProject = createAction<ProjectDto>('projects/setCurrentProject')
export const setCurrentProjectName = createAction<string>('projects/setCurrentProjectName')
export const setProjectSlidesOrder = createAction<number[]>('projects/setSlideOrder')
export const getProjectTemplates = createAction<Template[]>('project/getProjectTemplates')
export const setProjectTemplates = createAction<number[]>('projects/setProjectTemplates')
export const completeProject = createAction<number[]>('projects/completeProject')
export const getProjectLayouts = createAction<Layouts[]>('projects/getProjectLayout')
export const setProjectEditStatusByIds = createAction<any>('projects/setProjectEditStatusByIds')
